.infrastructure {
  section {
    display: block;
    height: 100%;
  }
  h1 {
    border-color: $secondary-color;
    color: $primary-color;
    @include breakpoint(medium) {
      margin-top: 6rem;
    }
  }
  // p {
  //   text-align: justify;
  // }
  .ending {
    height: 120px;
    width: 70px;
    background-image: url(../medias/img/icon-project.svg);
  }
  ul.listing li {
    width: 100%;
    margin-bottom: 2rem;
    color: $primary-color;
    @include breakpoint(medium) {
      width: 25%;
    }
    @include breakpoint(large) {
      width: 20%;
    }
    i {
      width: 5rem;
      height: 5rem;
      @include breakpoint(medium) {
        width: 8rem;
        height: 8rem;
      }
      &.vagrant {
        background-image: url(../medias/img/infra/vagrant.svg);
      }
      &.github {
        background-image: url(../medias/img/infra/github.svg);
      }
      &.chef {
        background-image: url(../medias/img/infra/chef.svg);
      }
      &.capistrano {
        background-image: url(../medias/img/infra/capistrano.svg);
      }
      &.ovh {
        background-image: url(../medias/img/infra/ovh.svg);
      }
      &.newrelic {
        background-image: url(../medias/img/infra/newrelic.svg);
      }
      &.sparkpost {
        background-image: url(../medias/img/infra/sparkpost.svg);
      }
    }
  }
}

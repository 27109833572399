.technologies {
  section {
    display: block;
    height: 100%;
  }
  h1 {
    border-color: $secondary-color;
    color: $white;
    @include breakpoint(medium) {
      margin-top: 6rem;
    }
  }
  .ending {
    width: 90px;
    height: 120px;
    background-image: url(../medias/img/icon-infra.svg);
  }
  ul.listing li {
    width: 100%;
    color: $white;
    @media (orientation: landscape) { 
      width: 30%;
    }
    @include breakpoint(medium) {
      width: 25%;
      margin-bottom: 2rem;
    }
    @include breakpoint(large) {
      width: 20%;
    }
    i {
      width: 5rem;
      height: 5rem;
      @include breakpoint(medium) {
        width: 8rem;
        height: 8rem;
      }
      &:hover {
        box-shadow: .25rem .25rem 0 $secondary-color;
      }
      &.symfony {
        background-image: url(../medias/img/technos/symfony.svg);
      }
      &.react {
        background-image: url(../medias/img/technos/react.svg);
      }
      &.elasticsearch {
        background-image: url(../medias/img/technos/elasticsearch.svg);
      }
      &.redux {
        background-image: url(../medias/img/technos/redux.svg);
      }
      &.redis {
        background-image: url(../medias/img/technos/redis.svg);
      }
      &.rabbitmq {
        background-image: url(../medias/img/technos/rabbitmq.svg);
      }
      &.travis {
        background-image: url(../medias/img/technos/travis.svg);
      }
    }
  }
}

.page-de-garde {
  color: $white;
  background-image: url(../medias/img/curves.svg);
  background-repeat: no-repeat;
  background-position: 25% 70%;
  background-size: 200%;
  .h1 {
    color: $white;
    font-size: 1rem;
    display: inline-block;
    font-weight: 300;
    .main-logo {
      display: inline-block;
      background: url(../medias/img/itnetwork.svg) no-repeat;
      background-size: 100% auto;
      width: 280px;
      height: 107px;
      text-indent: -9999em;
      @media (orientation: landscape) {
        width: 120px;
        height: 46px;
      }
      @include breakpoint(medium) {
        width: 300px;
        height: 115px;
        margin-left: -50px;
      }
      @include breakpoint(large) {
        margin-top: 5rem;
      }
    }
    .ext {
      display: block;
      text-align: right;
    }
    .baseline {
      display: block;
      margin-top: 1rem;
      color: rgba($white, .7);
      text-align: right;
    }
  }
  .mouse-icon {
    margin-bottom: 10rem;
  }
  .next span {
    display: block;
    width: 167px;
    height: 120px;
    overflow: hidden;
    text-indent: -9999em;
    margin-left: auto;
    margin-right: auto;
    background: url(../medias/img/itn-dot.svg) no-repeat 50% 50%;
    &.has-tip {
      border-bottom: none;
    }
  }
}

.gestion-de-projets {
  section {
    display: block;
    height: 100%;
  }
  h1 {
    border-color: $primary-color;
    color: $white;
    @include breakpoint(medium) {
      margin-top: 6rem;
    }
  }
  .ending {
    width: 60px;
    height: 100px;
    background-image: url(../medias/img/icon-contact.svg);
  }
  ul.listing li {
    width: 45%;
    margin-bottom: 2rem;
    color: $primary-color;
    @include breakpoint(medium) {
      width: 25%;
    }
    @include breakpoint(large) {
      width: 20%;
    }
    i {
      width: 5rem;
      height: 5rem;
      @include breakpoint(medium) {
        width: 8rem;
        height: 8rem;
      }
      &.trello {
        background-image: url(../medias/img/tools/trello.svg);
      }
      &.slack {
        background-image: url(../medias/img/tools/slack.svg);
      }
    }
  }
}

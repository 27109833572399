/* ---------------------------------------------- /*
 * Mouse animate icon
 * (c) https://gist.github.com/agragregra/d628a19e823d5a10ae56
/* ---------------------------------------------- */
.mouse-icon {
  border: 2px solid $white;
  border-radius: 16px;
  height: 40px;
  width: 24px;
  display: none;
  z-index: 10;
  opacity: 0.7;
  margin-left: auto;
  margin-right: auto;
  @include breakpoint(large) {
    display: block;
  }
}
.mouse-icon .wheel {
  -webkit-animation-name: drop;
  -webkit-animation-duration: .8s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
  -webkit-animation-name: drop;
          animation-name: drop;
  -webkit-animation-duration: .8s;
          animation-duration: .8s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.mouse-icon .wheel {
  position: relative;
  border-radius: 10px;
  background: $secondary-color;
  width: 10px;
  height: 10px;
  top: 2px;
  margin-left: auto;
  margin-right: auto;
}
@-webkit-keyframes drop {
  0% {
    top: 5px;
    opacity: 0;
  }
  30% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: 25px;
    opacity: 0;
  }
}
@keyframes drop {
  0% {
    top: 5px;
    opacity: 0;
  }
  30% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: 25px;
    opacity: 0;
  }
}

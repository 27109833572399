.references {
  section {
    display: block;
    height: 100%;
    color: $white;
  }
  h1 {
    border-color: $secondary-color;
    color: $white;
    @include breakpoint(medium) {
      margin-top: 6rem;
    }
  }
  .ending {
    height: 90px;
    width: 170px;
    background-image: url(../medias/img/skills.svg);
  }
  article {
    display: block;
    height: 50vh;
    min-height: 220px;
    margin-bottom: 2rem;
    @include breakpoint(medium) {
      height: 25vh;
    }
    overflow: hidden;
    position: relative;
    background: $white;
  }
  .default {
    display: block;
    position: relative;
    height: 100%;
    figure {
      position: relative;
      display: block;
      width: 100%;
      height: 70%;
      background: url(../medias/img/loading.svg) no-repeat 50% 50%;
      img {
        max-width: 90%;
        max-height: 100%;
        @include absolute-center;
        margin-top: 2rem;
        z-index: 2;
        &.others {
          margin-top: 0;
        }
      }
    }
    .abstract {
      display: block;
      position: absolute;
      width: 100%;
      bottom: 0;
      color: $white;
      background: rgba($primary-color, .5);
      padding: 2.4rem 1rem 0;
      height: 40%;
      h2 {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 1;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
  .desc {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 100%;
    z-index: 3;
    background: rgba($white, .8);
    color: $primary-color;
    padding: 1rem;
    transition: top .4s ease-In-Out;
    a {
      color: $primary-color;
      display: table;
      width: 100%;
      height: 100%;
    }
    i {
      display: block;
      width: 64px;
      height: 64px;
      background: rgba($black, .5) url(../medias/img/icon-magnifier.svg) no-repeat;
      background-size: 75% 75%;
      background-position: 50% 50%;
      margin-left: auto;
      margin-right: auto;
      border-radius: 100%;
      transition: background-color .3s ease-In-Out;
      &:hover {
        background-color: $primary-color;
        background-size: 80% 80%;
      }
    }
  }
  article:hover .desc {
    top: 0;
  }
}
.the-desc {
  color: $primary-color;
  text-align: left;
  h2 {
    color: $secondary-color;
    font-weight: bold;
  }
  h3 {
    border-bottom: solid 1px $secondary-color;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1rem;
  }
  a {
    display: inline-block;
    border-bottom: dotted 1px $primary-color;
  }
  figure {
    display: block;
    min-height: 100px;
    background: url(../medias/img/loading.svg) no-repeat 50% 50%;
  }
}
.ref-reveal {
  box-shadow: 0 0 2.4rem $black;
  top: 0 !important;
  min-height: 100%;
  @include breakpoint(large) {
    top: 50% !important;
    transform: translateY(-50%);
    min-height: 1em;
  }
}
// .scrollable-refs {
//   max-height: 50vh;
//   overflow: auto;
// }

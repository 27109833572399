.competences {
  section {
    display: block;
    height: 100%;
  }
  h1 {
    border-color: $primary-color;
    color: $white;
    @include breakpoint(medium) {
      margin-top: 6rem;
    }
  }
  p {
    text-align: justify;
  }
  .ending {
    height: 110px;
    width: 70px;
    background-image: url(../medias/img/icon-technos.svg);
  }
  ul.listing li {
    width: 100%;
    margin-bottom: 1rem;
    @include breakpoint(medium) {
      width: 33.3333%;
      margin-bottom: 2rem;
    }
    @include breakpoint(large) {
      width: 16%
    }
    i {
      width: 5rem;
      height: 5rem;
      @include breakpoint(medium) {
        width: 8rem;
        height: 8rem;
      }
      &.applications-metiers {
        background-image: url(../medias/img/icon-applications-metiers.svg);
      }
      &.progressive-web-apps {
        background-image: url(../medias/img/icon-progressive-apps.svg);
      }
      &.bots {
        background-image: url(../medias/img/icon-bots.svg);
      }
      &.e-commerce {
        background-image: url(../medias/img/icon-e-commerce.svg);
      }
      &.sites-web {
        background-image: url(../medias/img/icon-sites-web.svg);
      }
      &.intranets-extranets {
        background-image: url(../medias/img/icon-intranets-extranets.svg);
      }
    }
  }
}

.contact {
  section {
    display: block;
    height: 100%;
    color: $white;
  }
  h1 {
    border-color: $secondary-color;
    color: $primary-color;
    @include breakpoint(medium) {
      margin-top: 6rem;
    }
  }
  .ending {
    height: 64px;
    background-image: url(../medias/img/icon-up.svg);
    background-size: 18px 18px;
    background-position: 50% 50%;
  }
  .made {
    background: $primary-color;
    color: $white;
    font-size: 15px;
    padding: 1rem 0 2rem;
    span {
      //color: $alert-color;
      display: inline-block;
      margin-left: .25rem;
      margin-right: .25rem;
    }
    a {
      color: $white;
      transition: color .3s ease;
      &:hover {
        color: rgba($white, .5);
      }
    }
  }
  label {
    text-align: left;
    &.required:after {
      content: "*";
      color: $alert-color;
      font-weight: bold;
    }
  }
  #_gotcha {
    display: none;
  }
}

.l-agence {
  background: url(../medias/img/curves.svg) no-repeat 50% 55%;
  section {
    display: block;
    height: 100%;
    color: $primary-color;
  }
  h1 {
    border-color: $secondary-color;
    position: relative;
    text-align: center;
    @include breakpoint(medium) {
      margin-top: 6rem;
    }
    .logo {
      display: inline-block;
      background: url(../medias/img/itnetwork-black.svg) no-repeat;
      background-size: 100% auto;
      width: 140px;
      height: 48px;
      text-indent: -9999em;
      position: relative;
      margin-left: -.5em;
      margin-top: 10px;
      @include breakpoint(medium) {
        margin-top: 0;
        margin-left: .5em;
        top: -12px;
      }
    }
  }
  h2 {
    font-size: 1rem;
    font-weight: 300;
    @include breakpoint(medium) {
      text-align: left;
      font-size: 2rem;
    }
  }
  p {
    line-height: normal;
    @media (orientation: landscape) { 
      letter-spacing: -1px;
    }
    @include breakpoint(medium) {
      text-align: center;
      letter-spacing: 0;
      font-size: 1.5rem;
    }
  }
  .ending {
    width: 60px;
    height: 110px;
    background-image: url(../medias/img/icon-nos-references.svg);
  }
}

body {
  text-align: center;
  background: $primary-color;
  color: $white;
  font-weight: 300;
}
.ui-width {
  width: 100%;
  @include breakpoint(medium) {
    max-width: 80%;
  }
  @include breakpoint(large) {
    max-width: 90%;
  }
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
/*
Burger menu
*/
$menu-height: 36px;
.main-menu {
  position: fixed;
  z-index: 101;
  width: 100%;
  height: $menu-height;
  ul {
    display: none;
    a {
      color: $white;
    }
  }
  &.expanded {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: $primary-color;
    ul {
      display: block;
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        display: block;
        height: 10vh;
        vertical-align: middle;
        @include breakpoint(large) {
          display: inline-block;
          height: auto;
          margin-left: 1em;
          margin-right: 1em;
          vertical-align: bottom;
        }
        a {
          color: $secondary-color;
          font-size: 1.25rem;
          transition: color .3s ease;
          font-weight: bold;
          @include breakpoint(large) {
            font-weight: 300;
          }
          &:hover {
            color: $white;
          }
        }
      }
    }
  }
  button {
    cursor: pointer;
    margin: .5rem;
    @include breakpoint(medium) {
      width: calc(20px + 1rem);
      height: calc(20px + 1rem);
      border-radius: 100%;
      transition: background-color .3s ease-in-out;
    }
    &:before {
      display: inline-block;
      width: 20px;
      height: 18px;
      margin-top: 2px;
      background: url(../medias/img/icon-burger.svg) no-repeat;
      background-size: 100% 100%;
      content: "";
    }
    &:hover {
      @include breakpoint(medium) {
        background: rgba($black, .8);
      }
    }
  }
}
.menu-logo {
  display: inline-block;
  background: url(../medias/img/itnetwork.svg);
  width: 120px;
  height: 46px;
  background-size: 100% auto;
  text-indent: -9999em;
  margin-left: -25px;
  @include breakpoint(large) {
    margin-bottom: 5px;
  }
}
.blog-link {
  display: block;
  position: absolute;
  right: .5rem;
  top: 0;
  width: 4rem;
  height: 3.5rem;
  color: $white;
  text-align: center;
  padding-top: 1.25rem;
  font-size: $small-font-size;
  background: url(../medias/img/icon-blog.svg);
  background-size: 1.5rem auto;
  background-position: 50% 0;
  background-repeat: no-repeat;
  margin-top: .5rem;
  font-weight: bold;
  //-webkit-text-stroke: 1px $primary-color;
  text-shadow:
          1px  1px 0 $primary-color,
          -1px -1px 0 $primary-color,
          1px -1px 0 $primary-color,
          -1px  1px 0 $primary-color;
  letter-spacing: 2px;
  &:hover {
    color: $secondary-color;
  }
  @include breakpoint(medium) {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: $secondary-color;
    color: $white;
    top: -2rem;
    right: -3rem;
    width: 7rem;
    height: 5rem;
    padding-top: 0;
    margin-top: 0;
    transform: rotate(45deg);
    text-shadow: none;
    font-size: 1.2rem;
    letter-spacing: 0;
    line-height: 1.8;
    box-shadow: inset 0 -.2rem 1rem rgba($black, .5);
    text-transform: uppercase;
    transition: background-color .5s ease-in-out;
  }
  &:hover {
    @include breakpoint(medium) {
      color: $primary-color;
      background: $white;
    }
  }
}
// .section {
//   padding-top: $menu-height;
// }
/*
Fake tables
*/
.f-table {
  display: table;
  margin-left: auto;
  margin-bottom: auto;
  &.h-100 {
    height: 100%;
  }
  &.w-100 {
    width: 100%;
  }
}
.f-tr {
  display: table-row;
}
.f-td {
  display: table-cell;
  vertical-align: middle;
  &.a-b {
    vertical-align: bottom;
  }
  &.h-1 {
    height: 1vh;
  }
  &.h-10 {
    height: 10vh;
  }
  &.h-20 {
    height: 20vh;
  }
  &.h-30 {
    height: 30vh;
  }
  &.h-40 {
    height: 40vh;
  }
  &.h-50 {
    height: 50vh;
  }
}
.h-available {
  height: 100%;
}
/*
Headings
*/
h1 {
  display: inline-block;
  text-align: left;
  font-size: 2.25rem;
  font-weight: 200;
  letter-spacing: .125rem;
  color: $primary-color;
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1;
  @include breakpoint(medium) {
    padding-left: 2rem;
    border-left: solid 5px transparent;
    line-height: normal;
  }
  span {
    font-weight: 600;
  }
}
/*
Sections sep
*/
.next {
  display: block;
  background-image: url(../medias/img/next-is-white.svg);
  background-repeat: no-repeat;
  background-position: 50% 100%;
}
.next.is-primary {
  background-image: url(../medias/img/next-is-primary.svg);
}
.next.is-secondary {
  background-image: url(../medias/img/next-is-secondary.svg);
}
.next.is-last {
  background-image: url(../medias/img/next-is-last.svg);
  background-position: 50% 0;
}
.ending {
  display: block;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  text-indent: -9999em;
  margin-left: auto;
  margin-right: auto;
  &.has-tip {
    border-width: 0;
    border-color: transparent;
  }
}
/* ************************
 * Full page side nav
 * ************************ */
#fp-nav {
  display: none;
  @include breakpoint(medium) {
    display: block;
  }
}
#fp-nav ul,
.fp-slidesNav ul {
  margin-top: -100px;
}
#fp-nav ul li,
.fp-slidesNav ul li {
  width: 28px !important;
  height: 28px !important;
}
#fp-nav ul li a,
.fp-slidesNav ul li a {
  span {
    display: block;
    background-color: $primary-color !important;
    border: solid 1px $white !important;
    width: 10px !important;
    height: 10px !important;
    margin: -5px 0 0 -5px !important;
  }
}
#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span {
  width: 18px !important;
  height: 18px !important;
  background-color: $secondary-color !important;
  margin: -9px 0 0 -9px !important;
}
#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
  width: 18px !important;
  height: 18px !important;
  margin: -9px 0 0 -9px !important;
}
/*
Nav tooltips
*/
#fp-nav ul li .fp-tooltip {
  top: -6px !important;
  color: $white !important;
  background: $black;
  font-family: "Source Sans Pro", sans-serif !important;
  padding: 8px 1em;
  border-radius: 4px;
  font-weight: normal;
}
#fp-nav ul li .fp-tooltip.right {
  right: 33px !important;
}
//
// Fullpage hack
//
.fp-scrollable {
  //@include breakpoint(large) {
  height: auto !important;
  overflow: auto !important;
  //overflow: auto !important;
  //}
}
/*
Icons listing
*/
ul.listing {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    display: inline-block;
    margin: 0;
    padding: 0;
    vertical-align: top;
    img,
    i {
      display: none;
      @include breakpoint(medium) {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 50% 50%;
        width: 120px;
        background-color: #fff;
        border-radius: 100%;
        box-shadow: .25rem .25rem 0 rgba($primary-color,0);
        transition: all .3s ease;
      }
    }
    span {
      display: block;
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 1.25rem;
      //font-weight: 400;
      line-height: 1;
    }
    &:hover i {
      background-size: 110% 110%;
      // background-color: $black;
      box-shadow: .25rem .25rem 0 rgba($primary-color,.25);
    }
  }
}
/* Tooltip */
.has-tip {
  outline: none;
  &[data-template-classes="popup"] {
    cursor: pointer;
  }
}
.tooltip {
  max-width: 200px;
  box-shadow: 0 0 2rem rgba($black, .75);
}
.tooltip.popup {
  font-weight: normal;
  max-width: 96%;
  width: 96%;
  text-align: left;
  @include breakpoint(medium) {
    max-width: 50%;
    width: 50%;
  }
}
.reveal-overlay {
  background-color: lighten($primary-color,10%);
}

._404 {
  .main-logo {
    transform: rotate(-30deg);
    filter: grayscale(1);
  }
  #fp-nav {
    display: none;
  }
  .baseline {
    display: inline-block;
    margin-top: 5rem !important;
    a {
      color: $white;
      text-decoration: underline;
    }
  }
}
